import React from "react"
import ReactMarkdown from "react-markdown"
import {Container, Row} from 'reactstrap'
import {graphql} from 'gatsby'

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import BloqueIconoTexto from "../components/bloqueIconoTexto";
import {LangContext} from "../components/lurraldebusWrapper";

import oficinaMapa from "../assets/images/canales-de-contacto/mapa_oficina.png";

let OficinaAtencionBlock = ({banner, title, children}) => (
  <div>
    <img src={banner} alt={title} className="w-100 my-3"/>
    <h5 className="text-center font-weight-bold pb-3">{title}</h5>
    <ReactMarkdown source={children} className="text-center text-muted px-xl-5 mx-xl-5"/>
  </div>
)

const CanalesDeContactoPage = ({ data }) => (
  <LangContext.Consumer>
    { context => (
      <Layout>
        <SEO title={context.translate(data, 'canalesDeContacto', 'titulo')} />
        <Container className="py-5  mb-5">
          <PageTitle title={context.translate(data, 'canalesDeContacto', 'titulo')}/>
          <Row xs="1" md="3" className="mt-4">
            {context.translate(data, 'canalesDeContactoBloques', 'grupoContactos').map(item =>
              <BloqueIconoTexto
                key={item.id}
                icon={item.icono}
                title={item.titulo}
                children={item.descripcion}
              />
            )}
          </Row>
          <h5 className="text-center font-weight-bold pt-3 pb-2">{context.translate(data, 'oficinaDeAtencionAlCliente', 'titulo')}</h5>
          <OficinaAtencionBlock banner={oficinaMapa} title={context.translate(data, 'oficinaDeAtencionAlCliente', 'direccion')}>
            {context.translate(data, 'oficinaDeAtencionAlCliente', 'descripcion')}
          </OficinaAtencionBlock>
        </Container>
      </Layout>
    )}
  </LangContext.Consumer>
)

export default CanalesDeContactoPage

export const pageQuery = graphql`  
  query {
    canalesDeContacto: strapiCanalesDeContacto {
      titulo
    }
    canalesDeContacto_eus: strapiCanalesDeContactoEus {
      titulo
    }
    canalesDeContactoBloques: strapiContactoSeccion {
      grupoContactos {
        id
        titulo
        descripcion
        icono {
          publicURL
        }
      }
    }
    canalesDeContactoBloques_eus: strapiContactoSeccionEus {
      grupoContactos {
        id
        titulo
        descripcion
        icono {
          publicURL
        }
      }
    }
    oficinaDeAtencionAlCliente: strapiOficinaDeAtencionAlCliente {
      titulo
      descripcion
      direccion
    }
    oficinaDeAtencionAlCliente_eus: strapiOficinaDeAtencionAlClienteEus {
      titulo
      descripcion
      direccion
    }
  }
`